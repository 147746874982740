*, html{
    scroll-behavior: smooth;
}

*, *:after, *:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

:root{
    --white:#FFF;
    --black:#000;
    --lite:rgba(255,255,255,0.6);
    --gray:rgba(1,1,1,0.6);
    --dark:#1e1e1d;
    --primary:#d6863a;
    --primary_dark:#93683f;
    --primary_lite:#ebab61;
    --secondary:
    --secondary_dark:
    --secondary_lite:
    --default_font:'Nunito Sans', sans-serif;
    --title_font:
}

::-webkit-scrollbar {
    height: 12px;
    width: 4px;
    background: var(--dark);
}

::-webkit-scrollbar-thumb {
    background: gray;
    -webkit-box-shadow: 0px 1px 2px var(--dark);
}

::-webkit-scrollbar-corner {
    background: var(--dark);
}





/***************************
            DEFAULT
****************************/
body{
    margin:0;
    overflow-x:hidden !important;
    font-family: var(--default_font);
}

img{
    border-radius: 25px;
}




figure{
    padding:0;
    margin:0;
}

a, p, .btn{
    font-size:15px;
}

p{
    line-height:1.9em;
    color:var(--gray);
}






.title, .sub_title{
    font-family:var(--title_font);
    font-weight:800;
    margin:0;
}

.flex, .fixed_flex{
    display:flex;
}

.flex_content{
    width:100%;
    position:relative;
}



.padding_1x{
    padding:1rem;
}

.padding_2x{
    padding:3rem;
    padding-top: 0;
}
.heading h1{
    color: red;
    font-size: 2rem;
}
















@media (max-width:920px){
    .flex{
        flex-wrap:wrap;
    }
    
    .padding_1x, .padding_2x, .padding_3x, .padding_4x{
        padding:1rem;
    }
    
    .big{
        font-size:1.8em;
    }
    
    .medium{
        font-size:1.6em;
    }
    
    .small{
        font-size:1.1em;
    }
    
    .btn{
        padding:0.5rem 1rem;
    }
    
    a, p, .btn{
        font-size:12px;
    }
    
    .title_header{
        width:100%;
    }
}

/***************************
         FOOTER
****************************/
footer{
    width:100%;
    background-color:var(--dark);
    flex-wrap:wrap;
    align-items:center;
    justify-content:center;
    text-align:center;
}

footer section{
    flex:1 1 100%;
}

footer a, footer p{
    color:var(--lite);
}

footer .flex_content:first-child a:not(:last-child){
    margin-right:20px;
}

footer .flex_content a:not(:last-child){
    margin-right:50px;
}

footer .flex_content a .fa{
    font-size:1.6em;
}

footer .flex_content a:hover{
    color:var(--white);
}

@media (max-width:920px){
    footer{
        padding-top:2rem;
    }
    
    footer section{
        padding:0rem 1rem !important;
        text-align:left;
    }

    footer .flex_content:not(:first-child) a{
        margin-right:0px;
        margin:1rem 0;
        display:block;
    }
}



/***************************
        ADDITIONAL
****************************/


@Media (max-width:520px){
    .additional{
        max-width:100%;
        width:100%;
        left:50%;
        transform:translate(-50%, 0);
        padding:1rem;
    }
}

.overlay{
    position:fixed;
    top:0;
    left:0;
    z-index:1;
    background-color:var(--gray);
    width:100%;
    height:100%;
}

#roll_back{
    position:fixed;
    bottom:2rem;
    right:2rem;
    background-color:var(--dark);
    border-radius:5px;
    border:2px solid var(--primary);
    padding:5px 10px;
    display:none;
    align-items:center;
    justify-content:center;
    z-index:11;
    box-shadow:0px 6px 16px -6px rgba(1,1,1,0.5);
    color:var(--white);
    font-weight:900;
}


/***************************
           DIVISIONS
****************************/

/*DIVISION_2*/
.division_2 .flex_content:first-child{
    width:60%;
    text-align:center;
}

.division_2 figure{
    width:100%;
    position:relative;
    overflow:hidden;
}

.division_2 figure img{
    width:100%;
    object-fit:cover;
}

.division_2 .flex_content:first-child figure, .division_2 .flex_content:first-child figure img{
    height:100%;
}

.division_2 .flex_content:first-child figure figcaption{
    width:100%;
    position:absolute;
    top:26%;
    left:50%;
    transform:translate(-50%, -50%);
    background-color:var(--white);
    color:var(--dark);
    z-index:1;
}

.division_2 .cards{
    flex-wrap:wrap;
}

.division_2 .cards .card{
    flex:1 1 100%;
    color:var(--dark);
}

.division_2 .cards .card img{
    max-height:50vh;
    background-position: center;
}

.division_2 .cards .card img, .division_2 .cards .card figcaption{
    flex:1 1 50%;
}

.division_2 .cards .card:nth-child(even){
    text-align:right;
}

@media (max-width:1420px){
    .division_2 .cards .card img{
        max-height:30vh;
    }
}

@media (max-width:1180px){
    .division_2{
        flex-wrap:wrap;
    }
    
    .division_2 .flex_content:first-child{
        width:100%;
    }
    
    .division_2 .flex_content:first-child figure img{
        display:none;
    }
    
    .division_2 .flex_content:first-child figure figcaption{
        position:relative;
        width:100%;
      top: 70px;
    }
    
    .division_2 .cards .card img{
        max-height:50vh;
    }
}
    

@media (max-width:520px){
    .division_2, .division_1{
        padding:0 !important;
    }
    
    .division_2 .cards .card{
        display:block;
        border-radius:10px;
        overflow:hidden;
    }
    
    .division_2 .cards .card:not(:last-child){
        margin-bottom:1rem;
    }
    
    .division_2 .cards .card img{
        max-height:100%;
        border-radius:10px;
    }
    
    .division_2 .cards .card figcaption{
        position:absolute;
        background-color:var(--white);
        bottom:0;
        left:50%;
        width:90%;
        border-radius:10px;
        bottom:1rem;
        transform:translate(-50%, 0%);
        text-align:center;
    }
    
    .division_2 .cards .card:nth-child(even){
        text-align:center;
    }
    .hero-description {
        margin: 30px;
        font-size: 3rem;
    }
    .heading-primary {
        font-size: 2rem;
        margin: 30px;
        color: #333;
        font-weight: 700;
        letter-spacing: -0.5px;
        line-height: 1.05;
    }
    
}


/* ////////////////// */
.section-hero {
    /* background-color: #fdf2e9; */
    background-color: white;
    margin-top: 30px;
  }
  
  .hero {
    /* We'll not use px to define the length and will use rem. */
    /* max-width: 1300px; */

    max-width: 130rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 3.2rem;
    margin: 0 auto;
    align-items: center;
    gap: 0 9.6rem;
    margin-bottom: 50px
  }
  
  .heading-primary {
    font-size: 3rem;
    color: #333;
    font-weight: 700;
    letter-spacing: -0.5px;
    line-height: 1.05;
    margin-bottom: 3.6rem;
  }
  
  .hero-description {
    font-size: 4rem;
    line-height: 1.5;
    color: #EA8A23;
    margin-top: 0;
  }
  
  .hero-img-box {
    grid-column: 2/3;
    grid-row: 1 / 3;
  }
  
  .hero-img {
    width: 50%;
  }
  
  .btn:link,
  .btn:visited {
    display: inline-block;
    font-size: 2rem;
    font-weight: 600;
    font-family: inherit;
    border: none;
    cursor: pointer;
    border-radius: 9px;
    /* margin-top: 4.8rem; */
    padding: 1.6rem 3.2rem;
    text-decoration: none;
  
    transition: background-color 500ms;
  }
  
  .btn--fill:link,
  .btn--fill:visited {
    background-color: #e67e22;
    color: #fff;
  }
  
  .btn--outline:link,
  .btn--outline:visited {
    color: #555;
    background-color: #fff;
  }
  
  .btn--fill:hover,
  .btn--fill:active {
    background-color: #cf711f;
    text-decoration: underline;
  }
  
  .btn--outline:hover,
  .btn--outline:active {
    background-color: #fdf2e9;
    /* Now we'll not use border as in border the outlines are on the outside. Instead we'll use the outline or box-shadow property */
    box-shadow: inset 0px 0px 0px 3px #fff;
    text-decoration: underline;
    /* outline: #fff solid 3px; */
  }
  
  /* We'll create a utility class that can be used for any element by simply adding a class. */
  .margin-right-btn {
    margin-right: 2.4rem;
  }
  
  .delivered-meals {
    display: flex;
    align-items: center;
    gap: 3.2rem;
  }
  
  .delivered-imgs img {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    margin-right: -1.6rem;
    outline: #fdf2e9 solid 3px;
  }
  
  .delivered-text {
    font-size: 1.8rem;
    font-weight: 600;
  }
  
  .delivered-text span {
    color: #cf711f;
  }

/* ///////////////// */
.logo{
    width: 100px;
}

@media (max-width:520px){
    .hero-text-box{
     display: flex;
     flex-direction: column;
     justify-content: center;
    }
    .logo{
        margin-top: 30px;
        width: 80px;
        margin-left: 100px
    } 
    .hero-description{
        font-size: 3rem;
    }
    .heading-primary{
        font-size: 2rem;
        width: 100%;
    }
}